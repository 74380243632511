import React from "react";
import "./App.css";
import { Route, BrowserRouter } from "react-router-dom";
import { Redirect } from "react-router";
import { Helmet } from "react-helmet";
import ReactGA from "react-ga";
import Menu from "./navigation/Menu";
import Companies from "./pages/Companies";
import Jobs from "./pages/Jobs";
import Contact from "./pages/Contact";

ReactGA.initialize("UA-146770984-1");
ReactGA.set({ allowAdFeatures: "false" });
ReactGA.set({ anonymizeIp: "true" });

function App() {
  const options = [
    {
      display: "Companies",
      path: "/companies",
      component: Companies
    },
    {
      display: "Jobs",
      path: "/jobs",
      component: Jobs
    },
    {
      display: "Feedback & contact",
      path: "/contact",
      component: Contact
    }
  ];

  ReactGA.pageview(window.location.pathname + window.location.search);

  return (
    <div className="application">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Visapp</title>
        <meta
          name="description"
          content="An easily searchable list of UK Tier 2/Tier 5 visa sponsors, as well as a list of jobs that they offer."
        />
      </Helmet>
      <BrowserRouter>
        <div className="content">
          <Menu options={options} />
          {options.map(option => (
            <Route path={option.path} component={option.component} />
          ))}
          <Route exact path="/" render={() => <Redirect to="/companies" />} />
        </div>
      </BrowserRouter>
    </div>
  );
}

export default App;
