import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import { format } from "date-fns";

const styles = theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
    overflowX: "auto"
  },
  table: {
    minWidth: 700
  },
  tableCellNoBreak: {
    whiteSpace: "nowrap"
  }
});

function JobsList(props) {
  const {
    classes,
    jobs,
    columns,
    allowFilters,
    onFilterChanged,
    onChangePage,
    onChangeRowsPerPage,
    paginationEnabled,
    paginationPage,
    paginationRowsPerPage,
    paginationCount,
    categories,
    footer
  } = props;

  const paginationElement = (
    <TablePagination
      component="div"
      count={paginationCount}
      rowsPerPage={paginationRowsPerPage}
      rowsPerPageOptions={[10, 25, 50, 100]}
      page={paginationPage}
      backIconButtonProps={{
        "aria-label": "Previous Page"
      }}
      nextIconButtonProps={{
        "aria-label": "Next Page"
      }}
      onChangePage={onChangePage}
      onChangeRowsPerPage={onChangeRowsPerPage}
    />
  );

  return (
    <div className={classes.root}>
      {paginationEnabled && paginationElement}
      <Table>
        <TableHead>
          <TableRow>
            {columns.includes("jobName") && (
              <TableCell>
                {(allowFilters && (
                  <TextField
                    id="job"
                    label="Job name"
                    onChange={onFilterChanged}
                    margin="normal"
                  />
                )) ||
                  "Job name"}
              </TableCell>
            )}
            {columns.includes("company") && (
              <TableCell>
                {(allowFilters && (
                  <TextField
                    id="company"
                    label="Company"
                    onChange={onFilterChanged}
                    margin="normal"
                  />
                )) ||
                  "Company"}
              </TableCell>
            )}
            {columns.includes("matchedSponsor") && (
              <TableCell>
                {(allowFilters && (
                  <TextField
                    id="matchedgroup"
                    label="Matched sponsor"
                    onChange={onFilterChanged}
                    margin="normal"
                  />
                )) ||
                  "Matched sponsor"}
              </TableCell>
            )}
            {columns.includes("location") && (
              <TableCell>
                {(allowFilters && (
                  <TextField
                    id="location"
                    label="Location"
                    onChange={onFilterChanged}
                    margin="normal"
                  />
                )) ||
                  "Location"}
              </TableCell>
            )}
            {columns.includes("dateAdded") && <TableCell>Date Added</TableCell>}
            {columns.includes("sicCodes") && (
              <TableCell>
                {(allowFilters && (
                  <TextField
                    id="sicCodes"
                    label="Sic codes"
                    onChange={onFilterChanged}
                    margin="normal"
                  />
                )) ||
                  "Sic codes"}
              </TableCell>
            )}
            {columns.includes("category") && (
              <TableCell>
                {(allowFilters && (
                  <React.Fragment>
                    <InputLabel htmlFor="category">Category</InputLabel>
                    <Select
                      native
                      onChange={onFilterChanged}
                      input={<Input name="category" id="category" />}
                    >
                      {categories
                        .sort((a, b) => a.localeCompare(b))
                        .map(category => (
                          <option value={category}>{category}</option>
                        ))}
                    </Select>
                  </React.Fragment>
                )) ||
                  "Category"}
              </TableCell>
            )}

            {columns.includes("salary") && <TableCell>Salary</TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {jobs.map(job => (
            <TableRow>
              {columns.includes("jobName") && (
                <TableCell>
                  <Button
                    target="_blank"
                    href={job.link}
                    className={classes.button}
                  >
                    {job.job}
                  </Button>
                </TableCell>
              )}
              {columns.includes("company") && (
                <TableCell>{job.company}</TableCell>
              )}
              {columns.includes("matchedSponsor") && (
                <TableCell>{job.matchedSponsor}</TableCell>
              )}
              {columns.includes("location") && (
                <TableCell>{job.location}</TableCell>
              )}
              {columns.includes("dateAdded") && (
                <TableCell className={classes.tableCellNoBreak}>
                  {format(job.added, "DD MMM YY")}
                </TableCell>
              )}
              {columns.includes("sicCodes") && (
                <TableCell>{job.sicCodes.join(", ")}</TableCell>
              )}
              {columns.includes("category") && (
                <TableCell>{job.category}</TableCell>
              )}
              {columns.includes("salary") && (
                <TableCell>{job.salary}</TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {paginationEnabled && paginationElement}
      {footer && <TableFooter>{footer}</TableFooter>}
    </div>
  );
}

JobsList.propTypes = {
  classes: PropTypes.shape.isRequired,
  allowFilters: PropTypes.bool,
  jobs: PropTypes.arrayOf(
    PropTypes.shape({
      job: PropTypes.string.isRequired,
      company: PropTypes.string.isRequired,
      location: PropTypes.string.isRequired,
      added: PropTypes.string.isRequired,
      salary: PropTypes.string.isRequired,
      source: PropTypes.string.isRequired
    })
  ),
  categories: PropTypes.arrayOf(PropTypes.string),
  columns: PropTypes.arrayOf(PropTypes.string),
  onFilterChanged: PropTypes.func,
  onChangePage: PropTypes.func,
  onChangeRowsPerPage: PropTypes.func,
  paginationEnabled: PropTypes.bool,
  paginationPage: PropTypes.number,
  paginationRowsPerPage: PropTypes.number,
  paginationCount: PropTypes.number,
  footer: PropTypes.element
};

JobsList.defaultProps = {
  allowFilters: false,
  jobs: [],
  categories: [],
  columns: [
    "jobName",
    "company",
    "matchedSponsor",
    "location",
    "dateAdded",
    "sicCodes",
    "category",
    "salary"
  ],
  onFilterChanged: () => {},
  onChangePage: () => {},
  onChangeRowsPerPage: () => {},
  paginationEnabled: false,
  paginationPage: 1,
  paginationRowsPerPage: 25,
  paginationCount: 0,
  footer: null
};

export default withStyles(styles)(JobsList);
