import React from "react";
import LinearProgress from "@material-ui/core/LinearProgress";
import update from "immutability-helper";
import { format } from "date-fns";
import Paper from "@material-ui/core/Paper";
import FiltersList from "../components/FiltersList";
import JobsList from "../components/JobsList";
import { getCategoriesForCodes } from "../modules/siccodes";
import { Typography } from "@material-ui/core";

const PAGE_CHANGE_DELAY = 480;

class Jobs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      jobs: [],
      jobsCount: 0,
      rowsPerPage: 25,
      page: 0,
      filters: {},
      sourcesFilters: {},
      filtersTimeout: {},
      pageTimeout: null,
      importData: {},
      categories: [],
      sources: []
    };
    this.onFilterChanged = this.onFilterChanged.bind(this);
    this.onChangePage = this.onChangePage.bind(this);
  }

  componentDidMount() {
    fetch(`${VISAGE_API_URL}/jobs/meta`)
      .then(response => response.json())
      .then(response => {
        this.setState({ importData: response.data });
      });

    fetch(`${VISAGE_API_URL}/jobs/sources`)
      .then(response => response.json())
      .then(response => {
        const sources = response.data;
        this.setState({
          sources,
          sourcesFilters: sources.reduce(
            (prev, cur) => ({
              ...prev,
              [cur.id]: true
            }),
            {}
          )
        });
      })
      .then(() =>
        fetch(`${VISAGE_API_URL}/companies/categories`)
          .then(response => response.json())
          .then(response => {
            this.setState({ categories: response.data }, () =>
              this.updateList()
            );
          })
      );
  }

  onChangePage(event, newPage) {
    const { pageTimeout } = this.state;

    if (pageTimeout) {
      clearTimeout(pageTimeout);
    }

    this.setState({
      loading: true,
      page: newPage,
      pageTimeout: setTimeout(() => this.updateList(), PAGE_CHANGE_DELAY)
    });
  }

  onFilterChanged(event) {
    const name = event.target.id;
    const { filtersTimeout, categories } = this.state;
    if (filtersTimeout[name]) {
      clearTimeout(filtersTimeout[name]);
    }

    event.persist();

    const timeoutFunction = () => {
      const value = event.target.value.trim();
      let newState = {};
      if (name === "category") {
        const category = categories.find(cat => cat.name === value);

        if (category) {
          newState = update(this.state, {
            filters: {
              fromSicCode: { $set: category.sicCodeRange[0] },
              toSicCode: { $set: category.sicCodeRange[1] }
            }
          });
        }
      } else {
        newState = update(this.state, {
          filters: { [name]: { $set: value } }
        });
      }

      this.setState(newState, () => {
        this.updateList();
      });
    };

    const newState = update(this.state, {
      filtersTimeout: {
        [name]: { $set: setTimeout(timeoutFunction, 420) }
      }
    });
    this.setState(newState);
  }

  updateList() {
    const {
      filters,
      page,
      rowsPerPage,
      categories,
      sourcesFilters
    } = this.state;
    const query = new URLSearchParams({
      ...filters,
      page: page + 1,
      perPage: rowsPerPage,
      sources: JSON.stringify(
        Object.entries(sourcesFilters)
          .filter(entry => entry[1])
          .map(entry => entry[0])
      )
    });

    this.setState({
      loading: true
    });

    return fetch(`${VISAGE_API_URL}/jobs?${query.toString()}`)
      .then(response => response.json())
      .then(response => {
        this.setState({
          jobs: response.data.map(job => ({
            ...job,
            category: getCategoriesForCodes(categories, job.sicCodes).join(", ")
          })),
          jobsCount: response.count,
          loading: false
        });
      });
  }

  render() {
    const {
      loading,
      jobs,
      jobsCount,
      page,
      rowsPerPage,
      importData,
      categories,
      sources,
      sourcesFilters
    } = this.state;
    return (
      <div className="root">
        <Paper>
          <FiltersList
            onFilterChanged={(event, value) => {
              const newState = update(this.state, {
                sourcesFilters: { [event.target.value]: { $set: value } }
              });
              this.setState(newState, () => this.updateList());
            }}
            filters={sources.map(source => ({
              value: source.id,
              checked: sourcesFilters[source.id],
              label: source.displayName
            }))}
          />
        </Paper>
        <Paper>
          <LinearProgress hidden={!loading} />
          <JobsList
            allowFilters
            jobs={jobs}
            categories={[""].concat(categories.map(comp => comp.name))}
            paginationEnabled
            paginationPage={page}
            paginationRowsPerPage={rowsPerPage}
            paginationCount={jobsCount}
            onFilterChanged={this.onFilterChanged}
            onChangePage={this.onChangePage}
            onChangeRowsPerPage={event => {
              this.setState({ rowsPerPage: event.target.value }, () =>
                this.updateList()
              );
            }}
            footer={
              importData.lastImport && (
                <Typography variant="caption">
                  Last Import:{" "}
                  {format(importData.lastImport.date, "YYYY-MM-DD")}
                </Typography>
              )
            }
          />
        </Paper>
      </div>
    );
  }
}

export default Jobs;
