import _ from "lodash";

const getCategoriesForCodes = (categories, sicCodes) => {
  const companyCategories = sicCodes
    .map(code => {
      const category = categories.find(
        cat =>
          parseInt(code, 10) >= parseInt(cat.sicCodeRange[0], 10) &&
          parseInt(code, 10) <= parseInt(cat.sicCodeRange[1], 10)
      );
      return category ? category.name : null;
    })
    .filter(category => category !== null);
  return companyCategories.length ? _.uniq(companyCategories) : ["None"];
};

// eslint-disable-next-line import/prefer-default-export
export { getCategoriesForCodes };
