import React from "react";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import { TextField, Button, FormGroup } from "@material-ui/core";

const EMAIL = "visapp";

class ContactForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      message: ""
    };
    this.handleChange = this.handleChange.bind(this);
    this.sendRequest = this.sendRequest.bind(this);
  }

  sendRequest() {
    const { name, email, message } = this.state;
    const formData = new FormData();
    formData.append("name", name);
    formData.append("_replyto", email);
    formData.append("message", message);
    return fetch(`https://mailthis.to/${EMAIL}`, {
      method: "POST",
      body: formData
    }).then(() => {
      window.location.href = "https://mailthis.to/confirm";
    });
  }

  handleChange(stateName) {
    return event => {
      this.setState({
        [stateName]: event.target.value
      });
    };
  }

  isValid() {
    const { name, message } = this.state;
    return name && message.length >= 5;
  }

  render() {
    const { name, email, message } = this.state;
    return (
      <FormGroup>
        <FormControl>
          <InputLabel htmlFor="name">Name or nickname</InputLabel>
          <Input id="name" value={name} onChange={this.handleChange("name")} />
        </FormControl>
        <FormControl>
          <InputLabel htmlFor="email">Email address (optional)</InputLabel>
          <Input
            id="email"
            value={email}
            onChange={this.handleChange("email")}
          />
        </FormControl>
        <TextField
          label="Your message!"
          multiline
          rows="6"
          placeholder="Send over any problems you might have had, changes you would like to see, general thoughts and such!"
          margin="normal"
          variant="outlined"
          value={message}
          onChange={this.handleChange("message")}
          InputLabelProps={{
            shrink: true
          }}
        />
        <Button
          variant="contained"
          color="primary"
          disabled={!this.isValid()}
          onClick={this.sendRequest}
        >
          Send
        </Button>
      </FormGroup>
    );
  }
}

export default ContactForm;
