import React from "react";
import { Grid, Card, CardContent, Typography } from "@material-ui/core";
import { Helmet } from "react-helmet";
import ContactForm from "../components/ContactForm";

export default function Contact() {
  return (
    <div style={{ "padding-top": 20 }}>
      <Helmet>
        <script
          src="https://widget.surveymonkey.com/collect/website/js/tRaiETqnLgj758hTBazgd3XZtXjKDS3tXr4wMNujq92gEv0zD55co3PAliEr3pT8.js"
          type="text/javascript"
        />
      </Helmet>
      <Grid
        container
        spacing={8}
        direction="row"
        alignItems="flex-start"
        justify="space-evenly"
        style={{ minHeight: "40vh" }}
      >
        <Grid item xs={12} sm={8} md={4}>
          <Card>
            <CardContent>
              <Typography variant="h4">Get in touch</Typography>
              <ContactForm />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={8} md={4}>
          <Card>
            <CardContent>
              <div id="smcx-sdk" />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}
