import React from "react";
import Switch from "@material-ui/core/Switch";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import PropTypes from "prop-types";

function FiltersList(props) {
  const { filters, onFilterChanged, formLabel } = props;
  return (
    <FormGroup row>
      <FormLabel>{formLabel}</FormLabel>
      {filters.map(filter => (
        <FormControlLabel
          control={
            <Switch
              checked={filter.checked}
              onChange={onFilterChanged}
              value={filter.value}
            />
          }
          label={filter.label}
        />
      ))}
    </FormGroup>
  );
}

FiltersList.propTypes = {
  filters: PropTypes.shape,
  onFilterChanged: PropTypes.func,
  formLabel: PropTypes.string
};

FiltersList.defaultProps = {
  filters: {},
  onFilterChanged: () => {},
  formLabel: ""
};

export default FiltersList;
