import React from "react";
import Paper from "@material-ui/core/Paper";
import update from "immutability-helper";
import CompaniesList from "../components/CompaniesList";
import FiltersList from "../components/FiltersList";

class Companies extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filters: {}
    };
  }

  render() {
    const { filters } = this.state;
    return (
      <React.Fragment>
        <Paper>
          <FiltersList
            onFilterChanged={(event, value) => {
              const newState = update(this.state, {
                filters: { [event.target.value]: { $set: value } }
              });
              this.setState(newState);
            }}
            filters={[
              {
                value: "showActiveCompaniesOnly",
                label: "Active companies only"
              },
              {
                value: "showOnlyWithJobs",
                label: "Companies with jobs only"
              }
            ]}
          />
        </Paper>
        <Paper>
          <CompaniesList filters={filters} />
        </Paper>
      </React.Fragment>
    );
  }
}

export default Companies;
