import React from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import HelpIcon from "@material-ui/icons/Help";
import LinearProgress from "@material-ui/core/LinearProgress";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TextField from "@material-ui/core/TextField";
import update from "immutability-helper";
import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import Collapse from "@material-ui/core/Collapse";
import { Typography } from "@material-ui/core";
import JobsList from "./JobsList";
import { getCategoriesForCodes } from "../modules/siccodes";

const styles = theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
    overflowX: "auto"
  },
  table: {
    minWidth: 700
  }
});

const FILTER_CHANGE_DELAY = 420;
const PAGE_CHANGE_DELAY = 480;

class CompaniesList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rowsPerPage: 25,
      page: 0,
      companies: [],
      count: 0,
      filters: {},
      filtersTimeout: {},
      pageTimeout: null,
      categories: [],
      pdfUrl: "",
      collapsedRows: {},
      loading: false
    };
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
    this.handleChangeFilter = this.handleChangeTextFilter.bind(this);
    this.handleChangeCategoryFilter = this.handleChangeCategoryFilter.bind(
      this
    );
  }

  componentDidMount() {
    this.updateTable();
    this.updateTableMetadata();

    fetch(`${VISAGE_API_URL}/companies/meta`)
      .then(response => response.json())
      .then(response => this.setState({ pdfUrl: response.data.dataSource }));
  }

  componentDidUpdate(prevProps) {
    const { filters: oldFilters } = prevProps;
    const { filters } = this.props;

    if (!_.isEqual(oldFilters, filters)) {
      this.updateTable();
    }
  }

  updateTableMetadata() {
    fetch(`${VISAGE_API_URL}/companies/categories`)
      .then(response => response.json())
      .then(response => this.setState({ categories: response.data }));
  }

  updateTable() {
    const { page, rowsPerPage, filters } = this.state;
    const { filters: propFilters } = this.props;
    

    const query = new URLSearchParams({
      page: page + 1,
      perPage: rowsPerPage,
      jobs: propFilters.showOnlyWithJobs ? 1 : true,
      status: propFilters.showActiveCompaniesOnly ? "active" : "",
      ...filters
    });

    this.setState({ loading: true });
    return fetch(`${VISAGE_API_URL}/companies?${query.toString()}`)
      .then(response => response.json())
      .then(response =>
        this.setState({
          companies: response.data,
          count: response.count,
          loading: false
        })
      );
  }

  handleChangeTextFilter(name) {
    return event => {
      const { filtersTimeout } = this.state;
      if (filtersTimeout[name]) {
        clearTimeout(filtersTimeout[name]);
      }
      const newState = update(this.state, {
        filters: { [name]: { $set: event.target.value.trim() } },
        filtersTimeout: {
          [name]: {
            $set: setTimeout(() => this.updateTable(), FILTER_CHANGE_DELAY)
          }
        }
      });
      this.setState(newState);
    };
  }

  handleChangeCategoryFilter(event) {
    const { categories } = this.state;
    const category = event.target.value;

    const categoryInfo = categories.find(
      categoryEntry => categoryEntry.name === category
    );

    if (categoryInfo) {
      const { sicCodeRange } = categoryInfo;
      const newState = update(this.state, {
        filters: {
          fromSicCode: { $set: sicCodeRange[0] },
          toSicCode: { $set: sicCodeRange[1] }
        }
      });
      this.setState(newState, () => this.updateTable());
    } else {
      const newState = update(this.state, {
        filters: {
          fromSicCode: { $set: undefined },
          toSicCode: { $set: undefined }
        }
      });
      this.setState(newState, () => this.updateTable());
    }
  }

  handleChangePage(event, page) {
    const { pageTimeout } = this.state;

    if (pageTimeout) {
      clearTimeout(pageTimeout);
    }

    this.setState({
      loading: true,
      page,
      pageTimeout: setTimeout(() => this.updateTable(), PAGE_CHANGE_DELAY)
    });
  }

  handleChangeRowsPerPage(event) {
    this.setState({ rowsPerPage: event.target.value }, () =>
      this.updateTable()
    );
  }

  render() {
    const collapseComponent = props => (
      <td colSpan={8}>
        {" "}
        <div className={props.className}>{props.children}</div>
      </td>
    );
    const { classes } = this.props;
    const {
      companies,
      rowsPerPage,
      page,
      count,
      categories,
      pdfUrl,
      collapsedRows,
      filters,
      loading
    } = this.state;
    const pagination = (
      <TablePagination
        component="div"
        count={count}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[10, 25, 50, 100]}
        page={page}
        backIconButtonProps={{
          "aria-label": "Previous Page"
        }}
        nextIconButtonProps={{
          "aria-label": "Next Page"
        }}
        onChangePage={this.handleChangePage}
        onChangeRowsPerPage={this.handleChangeRowsPerPage}
      />
    );
    return (
      <div className={classes.root}>
        {pagination}
        <LinearProgress hidden={!loading} />
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>
                <TextField
                  id="name"
                  label="Company name"
                  onChange={this.handleChangeTextFilter("name")}
                  margin="normal"
                />
              </TableCell>
              <TableCell>
                <TextField
                  id="city"
                  label="City"
                  onChange={this.handleChangeTextFilter("city")}
                  margin="normal"
                />
              </TableCell>
              <TableCell>
                <TextField
                  id="tier"
                  label="Visa tier"
                  onChange={this.handleChangeTextFilter("tier")}
                  margin="normal"
                />
              </TableCell>
              <TableCell>
                <TextField
                  id="subtier"
                  label="Visa subtier"
                  onChange={this.handleChangeTextFilter("subtier")}
                  margin="normal"
                />
              </TableCell>
              <TableCell>
                <InputLabel htmlFor="company-category">Category</InputLabel>
                <Select
                  native
                  value={filters.category}
                  onChange={this.handleChangeCategoryFilter}
                  input={<Input name="category" id="company-category" />}
                >
                  <option value="" />
                  {categories
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map(category => (
                      <option value={category.name}>{category.name}</option>
                    ))}
                </Select>
              </TableCell>
              <TableCell>
                <div style={{ display: "flex", maxWidth: 100 }}>
                  <TextField
                    id="siccode"
                    label="SIC Code"
                    onChange={this.handleChangeFilter("sicCodes")}
                    margin="normal"
                  />
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    style={{ verticalAlign: "bottom" }}
                    href="https://resources.companieshouse.gov.uk/sic/"
                  >
                    <HelpIcon />
                  </a>
                </div>
              </TableCell>
              <TableCell>Company status</TableCell>
              <TableCell>Companies House</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {companies.slice(0, rowsPerPage).map((n, idx) => (
              <React.Fragment>
                <TableRow
                  key={n.index}
                  hover
                  style={{ cursor: n.jobs.length ? "pointer" : "auto" }}
                  onClick={() => {
                    if (n.jobs.length === 0) {
                      return;
                    }
                    this.setState({
                      collapsedRows: {
                        [idx]: !collapsedRows[idx]
                      }
                    });
                  }}
                >
                  <TableCell component="th" scope="row">
                    {n.jobs.length > 0 &&
                      `${n.name} (${n.jobs.length} jobs found)`}
                    {n.jobs.length === 0 && n.name}
                  </TableCell>
                  <TableCell>{n.city}</TableCell>
                  <TableCell>{n.tier.join(", ")}</TableCell>
                  <TableCell>{n.subtier.join(", ")}</TableCell>
                  <TableCell>
                    {getCategoriesForCodes(categories, n.sicCodes).join(", ")}
                  </TableCell>
                  <TableCell>{n.sicCodes.join(", ")}</TableCell>
                  <TableCell>
                    {n.status.charAt(0).toUpperCase() + n.status.slice(1)}
                  </TableCell>
                  <TableCell>
                    {n.link &&
                      n.link.length > 0 && (
                        <Button
                          target="_blank"
                          href={n.link}
                          className={classes.button}
                        >
                          Link
                        </Button>
                      )}
                  </TableCell>
                </TableRow>
                {n.jobs.length > 0 && (
                  <Collapse
                    in={collapsedRows[idx]}
                    timeout="auto"
                    component={collapseComponent}
                    unmountOnExit
                  >
                    <JobsList
                      jobs={n.jobs}
                      columns={[
                        "jobName",
                        "company",
                        "location",
                        "dateAdded",
                        "salary",
                        "source"
                      ]}
                    />
                  </Collapse>
                )}
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
        {pagination}
        <TableFooter>
          {pdfUrl && (
            <Typography variant="caption">
              Source:
              <a rel="noopener noreferrer" target="_blank" href={pdfUrl}>
                {pdfUrl}
              </a>
            </Typography>
          )}
        </TableFooter>
      </div>
    );
  }
}

CompaniesList.propTypes = {
  classes: PropTypes.shape.isRequired,
  filters: PropTypes.shape
};

CompaniesList.defaultProps = {
  filters: {}
};

export default withStyles(styles)(CompaniesList);
